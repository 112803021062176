<template>
  <div data-cy="asset-overview-page">
    <div class="mb-5">
      <div class="row">
        <div v-if="primaryImage" class="col-xl-4 col-lg-6 mb-4 mb-xl-0">
          <img :src="primaryImage.url" class="asset-image rounded-md" alt="Asset Image" />
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="bg-lighter p-4 rounded-md h-100" data-cy="asset-information">
            <div class="font-w600 text-muted mb-4 font-size-h4"><i class="fa fa-building mr-1"></i> Asset Information</div>

            <TextListItem icon="fa-tag" class="mb-2" label="Site Name">{{ asset.siteName }}</TextListItem>
            <TextListItem icon="fa-tag" class="mb-2" label="Site Code">{{ asset.siteCode }}</TextListItem>
            <TextListItem icon="fa-map-location" class="mb-2" label="Street Address">{{ asset.address | address }}</TextListItem>
            <TextListItem icon="fa-earth-europe" class="mb-2" uppercase label="Country">{{ asset.address.countryCode }}</TextListItem>
            <TextListItem icon="fa-tags" class="mb-2" label="Category">{{ asset.assetType }}</TextListItem>
            <TextListItem icon="fa-users" class="mb-2" capitalize label="Tenure">{{ asset.tenure }}</TextListItem>
            <TextListItem icon="fa-layer-group" class="mb-2" label="Floor Area"
              >{{ asset.floorArea | numberFormat }} {{ asset.floorAreaUnit | floorAreaUnit }}</TextListItem
            >
            <TextListItem icon="fa-building-circle-check" class="mb-2" capitalize label="Occupation Status">{{
              asset.occupationStatus
            }}</TextListItem>
            <TextListItem icon="fa-circle-dollar" class="mb-2" uppercase label="Gross Asset Value"
              >{{ asset.gavCurrency }} {{ asset.gav | numberFormat }}</TextListItem
            >
            <TextListItem icon="fa-person-digging" class="mb-2" label="Construction Year">{{ asset.constructionYear }}</TextListItem>
            <TextListItem icon="fa-calendar" class="mb-2" label="Owned Since">{{ asset.ownedSince }}</TextListItem>
            <TextListItem icon="fa-calendar-xmark" class="mb-2" label="Disposal Date">{{ asset.disposalDate }}</TextListItem>
          </div>
        </div>
        <div class="col">
          <div class="bg-lighter p-4 rounded-md h-100">
            <Spinner v-if="loadingAction.getAnalytics['elec-consumption'] || loadingAction.getAnalytics['gas-consumption']" />
            <div v-else data-cy="asset-consumption">
              <div class="d-flex justify-content-between">
                <div class="font-w600 text-muted font-size-h4"><i class="fa fa-bolt mr-1"></i> Electricity & Gas Consumption Summary</div>
                <div style="position: relative">
                  <div style="position: absolute; top: 0px; right: 18px">
                    <small class="text-muted mt-0 pt-0"><strong>SOURCE</strong></small>
                  </div>
                  <FormItem
                    id="source-dropdown"
                    v-model="source"
                    type="select"
                    label="Source"
                    :config="{ transparent: true }"
                    :options="[
                      { label: 'Combined', value: 'combined' },
                      { label: 'Custom Consumption', value: 'custom' },
                      { label: 'Invoice', value: 'invoice' },
                      { label: 'Readings', value: 'reading' },
                      { label: 'HH Data', value: 'hh' }
                    ]"
                    @input="fetchAnalytics"
                  />
                </div>
              </div>
              <apexchart
                v-if="analytics['elec-consumption']?.data"
                height="364"
                :options="benchmarkConsumptionLineChart.options"
                :series="benchmarkConsumptionLineChart.series"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <SectionTitle>Google Maps & Street View</SectionTitle>
      <GoogleMap
        v-if="asset.address && asset.address.latitude"
        height="550"
        :map-config="assetMapOptions"
        :street-view-config="assetStreetViewOptions"
      />
    </div>

    <div v-if="!permissions.permissions.some(p => p.code === 'meter_user')" class="mb-5">
      <SectionTitle>Portfolios <InfoPopover text="Portfolios that this asset is a member of" /></SectionTitle>
      <div v-for="assetGroup in sortedAssetGroups" :key="assetGroup._id" class="font-w600 mb-2">
        <router-link :to="{ name: 'portfolio-assets', params: { id: assetGroup._id } }">{{ assetGroup.name }}</router-link>
      </div>
    </div>

    <div class="mb-5">
      <SectionTitle>
        <template> Risks <InfoPopover text="Associated risks with this asset" /> </template>

        <template v-slot:actions>
          <router-link :to="{ name: 'asset-risks', params: { id: asset._id } }" class="btn btn-light">Show all risks</router-link>
        </template>
      </SectionTitle>
      <div class="row">
        <div class="col-md-2">
          <SummaryBox title="Flood Risks" icon="fa-water text-info">{{ asset.floodRisks?.length }}</SummaryBox>
        </div>
        <div class="col-md-2">
          <SummaryBox title="Recent Crimes" icon="fa-siren-on text-danger">{{ asset.recentCrimes?.length }}</SummaryBox>
        </div>
        <div class="col-md-2">
          <SummaryBox title="Air Quality Index" icon="fa-wind text-muted">{{ asset.recentAirQuality?.indexRating }}</SummaryBox>
        </div>
      </div>
    </div>

    <div v-if="!permissions.permissions.some(p => p.code === 'meter_user')" class="mb-5">
      <SectionTitle>
        <template> Timeline <InfoPopover text="Timeline of event related to this asset" /> </template>
      </SectionTitle>
      <AssetTimeline />

      <!-- <div class="row">
        <div class="col-md-2">
          <SummaryBox title="Flood Risks" icon="fa-water text-info">{{ asset.floodRisks.length }}</SummaryBox>
        </div>
        <div class="col-md-2">
          <SummaryBox title="Recent Crimes" icon="fa-siren-on text-danger">{{ asset.recentCrimes.length }}</SummaryBox>
        </div>
        <div class="col-md-2">
          <SummaryBox title="Air Quality Index" icon="fa-wind text-muted">{{ asset.recentAirQuality.indexRating }}</SummaryBox>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import AssetTimeline from './AssetTimeline';
import FormItem from '@/components/FormItem';
import GoogleMap from '@/components/GoogleMap';
import SectionTitle from '@/components/base/SectionTitle';
import TextListItem from '@/components/base/TextListItem';
import InfoPopover from '@/components/base/InfoPopover';
import Spinner from '@/components/Spinner';
import SummaryBox from '@/components/base/SummaryBox';

import { benchmarkConsumptionLineChart } from '../../../lib/consumption';

export default {
  name: 'AssetOverview',
  components: {
    AssetTimeline,
    FormItem,
    GoogleMap,
    SectionTitle,
    TextListItem,
    InfoPopover,
    SummaryBox,
    Spinner
  },
  data() {
    return {
      source: 'combined'
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions',
      asset: 'asset/asset',
      assetGroups: 'asset/assetGroups',
      analytics: 'analytics/analytics',
      loadingAction: 'analytics/loadingAction'
    }),
    assetMapOptions() {
      return {
        center: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        zoom: 18,
        mapTypeId: 'satellite'
      };
    },
    assetStreetViewOptions() {
      return {
        position: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        pov: {
          heading: this.asset.address.heading || 0,
          pitch: this.asset.address.pitch || 0
        },
        zoom: 0
      };
    },
    sortedAssetGroups() {
      const assetGroups = [...this.assetGroups];

      assetGroups.sort((a, b) => a.name.localeCompare(b.name));

      return assetGroups;
    },
    primaryImage() {
      if (!this.asset.images || !this.asset.images.length) return null;

      const firstPrimary = this.asset.images.find(image => image.isPrimary);

      if (firstPrimary) return firstPrimary;

      return this.asset.images[0];
    },
    floorAreaMetric() {
      return this.asset.floorAreaUnit === 'metric' ? this.asset.floorArea : this.asset.floorArea * 0.092903;
    },
    benchmarkConsumptionLineChart() {
      return benchmarkConsumptionLineChart({
        elecConsumption: this.analytics['elec-consumption']?.data,
        gasConsumption: this.analytics['gas-consumption']?.data,
        floorAreaMetric: this.floorAreaMetric
      });
    }
  },
  mounted() {
    this.fetchAnalytics();
  },
  methods: {
    ...mapActions({
      getAnalyticsV2: 'analytics/getAnalyticsV2'
    }),
    async fetchAnalytics() {
      const params = {
        startDate: moment().subtract(13, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        assetId: this.asset._id,
        id: 'consumption',
        dataType: 'asset',
        source: this.source,
        granularity: 'monthly'
      };

      this.getAnalyticsV2({
        id: 'elec-consumption',
        params: {
          ...params,
          accountType: 'electricity'
        }
      });

      this.getAnalyticsV2({
        id: 'gas-consumption',
        params: {
          ...params,
          accountType: 'gas'
        }
      });
    }
  }
};
</script>
<style scoped>
.asset-image {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

::v-deep .select2-selection__rendered {
  text-align: right;
  padding-top: 10px;
}

::v-deep .select2-transparent + .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 27px;
}
</style>
