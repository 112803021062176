var render = function render(){
  var _vm$analytics$elecCo, _vm$asset$floodRisks, _vm$asset$recentCrime, _vm$asset$recentAirQu;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-overview-page"
    }
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.primaryImage ? _c('div', {
    staticClass: "col-xl-4 col-lg-6 mb-4 mb-xl-0"
  }, [_c('img', {
    staticClass: "asset-image rounded-md",
    attrs: {
      "src": _vm.primaryImage.url,
      "alt": "Asset Image"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "col-xl-4 col-lg-6"
  }, [_c('div', {
    staticClass: "bg-lighter p-4 rounded-md h-100",
    attrs: {
      "data-cy": "asset-information"
    }
  }, [_vm._m(0), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-tag",
      "label": "Site Name"
    }
  }, [_vm._v(_vm._s(_vm.asset.siteName))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-tag",
      "label": "Site Code"
    }
  }, [_vm._v(_vm._s(_vm.asset.siteCode))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-map-location",
      "label": "Street Address"
    }
  }, [_vm._v(_vm._s(_vm._f("address")(_vm.asset.address)))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-earth-europe",
      "uppercase": "",
      "label": "Country"
    }
  }, [_vm._v(_vm._s(_vm.asset.address.countryCode))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-tags",
      "label": "Category"
    }
  }, [_vm._v(_vm._s(_vm.asset.assetType))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-users",
      "capitalize": "",
      "label": "Tenure"
    }
  }, [_vm._v(_vm._s(_vm.asset.tenure))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-layer-group",
      "label": "Floor Area"
    }
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.asset.floorArea)) + " " + _vm._s(_vm._f("floorAreaUnit")(_vm.asset.floorAreaUnit)))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-building-circle-check",
      "capitalize": "",
      "label": "Occupation Status"
    }
  }, [_vm._v(_vm._s(_vm.asset.occupationStatus))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-circle-dollar",
      "uppercase": "",
      "label": "Gross Asset Value"
    }
  }, [_vm._v(_vm._s(_vm.asset.gavCurrency) + " " + _vm._s(_vm._f("numberFormat")(_vm.asset.gav)))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-person-digging",
      "label": "Construction Year"
    }
  }, [_vm._v(_vm._s(_vm.asset.constructionYear))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-calendar",
      "label": "Owned Since"
    }
  }, [_vm._v(_vm._s(_vm.asset.ownedSince))]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-calendar-xmark",
      "label": "Disposal Date"
    }
  }, [_vm._v(_vm._s(_vm.asset.disposalDate))])], 1)]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "bg-lighter p-4 rounded-md h-100"
  }, [_vm.loadingAction.getAnalytics['elec-consumption'] || _vm.loadingAction.getAnalytics['gas-consumption'] ? _c('Spinner') : _c('div', {
    attrs: {
      "data-cy": "asset-consumption"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "font-w600 text-muted font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-bolt mr-1"
  }), _vm._v(" Electricity & Gas Consumption Summary")]), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "0px",
      "right": "18px"
    }
  }, [_c('small', {
    staticClass: "text-muted mt-0 pt-0"
  }, [_c('strong', [_vm._v("SOURCE")])])]), _c('FormItem', {
    attrs: {
      "id": "source-dropdown",
      "type": "select",
      "label": "Source",
      "config": {
        transparent: true
      },
      "options": [{
        label: 'Combined',
        value: 'combined'
      }, {
        label: 'Custom Consumption',
        value: 'custom'
      }, {
        label: 'Invoice',
        value: 'invoice'
      }, {
        label: 'Readings',
        value: 'reading'
      }, {
        label: 'HH Data',
        value: 'hh'
      }]
    },
    on: {
      "input": _vm.fetchAnalytics
    },
    model: {
      value: _vm.source,
      callback: function ($$v) {
        _vm.source = $$v;
      },
      expression: "source"
    }
  })], 1)]), (_vm$analytics$elecCo = _vm.analytics['elec-consumption']) !== null && _vm$analytics$elecCo !== void 0 && _vm$analytics$elecCo.data ? _c('apexchart', {
    attrs: {
      "height": "364",
      "options": _vm.benchmarkConsumptionLineChart.options,
      "series": _vm.benchmarkConsumptionLineChart.series
    }
  }) : _vm._e()], 1)], 1)])])]), _c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', [_vm._v("Google Maps & Street View")]), _vm.asset.address && _vm.asset.address.latitude ? _c('GoogleMap', {
    attrs: {
      "height": "550",
      "map-config": _vm.assetMapOptions,
      "street-view-config": _vm.assetStreetViewOptions
    }
  }) : _vm._e()], 1), !_vm.permissions.permissions.some(function (p) {
    return p.code === 'meter_user';
  }) ? _c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', [_vm._v("Portfolios "), _c('InfoPopover', {
    attrs: {
      "text": "Portfolios that this asset is a member of"
    }
  })], 1), _vm._l(_vm.sortedAssetGroups, function (assetGroup) {
    return _c('div', {
      key: assetGroup._id,
      staticClass: "font-w600 mb-2"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'portfolio-assets',
          params: {
            id: assetGroup._id
          }
        }
      }
    }, [_vm._v(_vm._s(assetGroup.name))])], 1);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('router-link', {
          staticClass: "btn btn-light",
          attrs: {
            "to": {
              name: 'asset-risks',
              params: {
                id: _vm.asset._id
              }
            }
          }
        }, [_vm._v("Show all risks")])];
      },
      proxy: true
    }])
  }, [[_vm._v(" Risks "), _c('InfoPopover', {
    attrs: {
      "text": "Associated risks with this asset"
    }
  })]], 2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('SummaryBox', {
    attrs: {
      "title": "Flood Risks",
      "icon": "fa-water text-info"
    }
  }, [_vm._v(_vm._s((_vm$asset$floodRisks = _vm.asset.floodRisks) === null || _vm$asset$floodRisks === void 0 ? void 0 : _vm$asset$floodRisks.length))])], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('SummaryBox', {
    attrs: {
      "title": "Recent Crimes",
      "icon": "fa-siren-on text-danger"
    }
  }, [_vm._v(_vm._s((_vm$asset$recentCrime = _vm.asset.recentCrimes) === null || _vm$asset$recentCrime === void 0 ? void 0 : _vm$asset$recentCrime.length))])], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('SummaryBox', {
    attrs: {
      "title": "Air Quality Index",
      "icon": "fa-wind text-muted"
    }
  }, [_vm._v(_vm._s((_vm$asset$recentAirQu = _vm.asset.recentAirQuality) === null || _vm$asset$recentAirQu === void 0 ? void 0 : _vm$asset$recentAirQu.indexRating))])], 1)])], 1), !_vm.permissions.permissions.some(function (p) {
    return p.code === 'meter_user';
  }) ? _c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', [[_vm._v(" Timeline "), _c('InfoPopover', {
    attrs: {
      "text": "Timeline of event related to this asset"
    }
  })]], 2), _c('AssetTimeline')], 1) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-w600 text-muted mb-4 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-building mr-1"
  }), _vm._v(" Asset Information")]);

}]

export { render, staticRenderFns }